<template>
  <div class="home">
    <div class="content">
      <div class="top noprint">
        <Header></Header>
      </div>
      <div class="middle">
        <div class="left noprint">
          <LeftBarVue></LeftBarVue>
        </div>
        <div class="right">
          <router-view class="routes" />
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>

import Header from './Header.vue'
import auth from '../methods/auth'
import { defineComponent, onMounted, onUnmounted, ref, reactive, getCurrentInstance } from 'vue'
import LeftBarVue from './LeftBar.vue';
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
const { state, commit } = useStore()
const router = useRouter()
const instance = getCurrentInstance()
let timer = ref()
let timer2 = ref()
let timer3 = ref()
let alarmEnable = ref(false)
let areaData = reactive(
  { items: [] })
window.addEventListener(
  "click",
  () => {
    // 为了方便，我们把点击事件的时间直接存到sessionStorage中去，这样方便获取比较
    sessionStorage.setItem("lastClickTime", new Date().getTime().toString());
  },
  true
);
function isTimeOut() {
  // 使用定时器之前，要清除一下定时器
  clearInterval(Number(timer));
  // clearInterval(timer);
  timer.value = window.setInterval(() => {
    // setInterval不能用 要用window.setInterval 计时器"不可分配给类型
    let lastClickTime = Number(sessionStorage.getItem("lastClickTime"));
    let nowTime = new Date().getTime(); // 获取当前时间
    if (router.currentRoute.value.name == "Login") return;
    console.log("当前时间和之前点击时间", nowTime, lastClickTime);
    // 假设我们需求是：5分钟不进行点击操作，就提示登录退出
    if (nowTime - lastClickTime > 1000 * 60 * 30) {
      clearInterval(Number(timer));
      console.log(111);
      ElMessage({
        type: "success",
        message: "长时间未操作，请重新登录"
      });
      sessionStorage.clear();
      // router.push({ path: "/login" });
    }
  }, 6000 * 5);
  console.log(timer);
}
function getSettings() {
  let val = {
    'key': 'preference'
  }
  instance.proxy.$http.post('/api/v2.1/center/get_user_data', val).then(res => {
    if (res.error == 0) {
      console.log(res)
      if (res.data.setting) {
        sessionStorage.setItem('user_data', JSON.stringify(res.data.setting))
        let glucoseLimites = JSON.parse(res.data.setting.glucoseLimites) || []
        let target_Low = 3.9
        let target_High = 10
        alarmEnable.value = res.data.setting.alarmEnable
        if (glucoseLimites.length > 0) {
          const maxIndex = glucoseLimites.reduce((maxIdx, obj, idx) => (obj.high > glucoseLimites[maxIdx].high ? idx : maxIdx), 0);
          const minIndex = glucoseLimites.reduce((maxIdx, obj, idx) => (obj.low < glucoseLimites[maxIdx].low ? idx : maxIdx), 0);
          target_Low = glucoseLimites[minIndex].low || 3.9
          target_High = glucoseLimites[maxIndex].high || 10
          sessionStorage.setItem('target_Low', target_Low.toString())
          sessionStorage.setItem('target_High', target_High.toString())
        } else {

          sessionStorage.setItem('target_Low', target_Low.toString())
          sessionStorage.setItem('target_High', target_High.toString())
        }
      }
    }
  })
}
function getPatienList() {
  let val = {
    st: new Date().getTime(),
    hospital_id: 1,
    get_delete_flag: false,
    add_sg: true
  }

  instance.proxy.$http.post('/api/v2.1/center/patient/get_list', val).then(res => {
    let error = res.error
    if (error == 0) {

      // 新增专业数据互通，进行专业版数据查询
      let arrPhone = []
      if(res.authority){
          sessionStorage.setItem('authority', JSON.stringify(res.authority))
      }
      for (let i = 0; i < res.patient.length; i++) {
        if (res.patient[i].easyview_uid && res.patient[i].mobile_number) {
          arrPhone.push(res.patient[i].mobile_number)
        }
      }
      let valPhone = {
        "username_list": arrPhone
      }
      instance.proxy.$http.post('/api/v2.1/center/easyview_proxy/get_sensor_status', valPhone).then(res2 => {
        let obj = res2.data
        for (let k = 0; k < res.patient.length; k++) {
          let phoneNumer = res.patient[k].mobile_number
          let easyview_uid = res.patient[k].easyview_uid
          let sn = res.patient[k].transmitter_sn

          if (phoneNumer && easyview_uid && obj[phoneNumer] && obj[phoneNumer].sensor_status && obj[phoneNumer].sensor_status.updateTime && sn == Number(obj[phoneNumer].sensor_status.serial).toString(16).padStart(8, '0').toLocaleUpperCase()) {
            res.patient[k].sg.push(...obj[phoneNumer].sg)
            for (let m = 0; m < obj[phoneNumer].bg.length; m++) {
              let item = [
                obj[phoneNumer].bg[m][2],
                obj[phoneNumer].bg[m][0],
                '',
                obj[phoneNumer].bg[m][1],
                ''
              ]
              res.patient[k].bg.push(item)
            }
            if (!res.patient[k].status.updateTime) {
              res.patient[k].status = obj[phoneNumer].sensor_status

              res.patient[k].ispro = true
            } else if (obj[phoneNumer].sensor_status.updateTime >= res.patient[k].status.updateTime) {
              res.patient[k].status = obj[phoneNumer].sensor_status
              res.patient[k].ispro = true
            }
          }
        }
        //新增一个函数来处理预警相关功能
        // commit('clear_Alert',null)


        sessionStorage.setItem('patienList', JSON.stringify(res.patient))
      })
    }
  })
}
function getAlerm() {
  
  let patienList = sessionStorage.getItem('patienList')
  if (patienList) {
    let patient = JSON.parse(patienList)
    for (let i = 0; i < patient.length; i++) {
      let item = patient[i]
      // 1.获取报警血糖门限
      // 2.比较当前血糖是否报警
      // 3.新增报警记录一条

      let sg = auth.showGlucoseStatus(item)
      togetTarger(item.setting)
      getSgLim(sg, areaData.items, item.patient_info, item.id, item)

    }
  }
}
function togetTarger(settings) {
  areaData.items.length = 0

  if (settings && settings.glucoseLimites && settings.glucoseLimites.length > 0) {
    // 要生成一个数组，用来存储在不同时间时值的范围 
    for (let i = 0; i < settings.glucoseLimites.length; i++) {
      let val
      if (i == settings.glucoseLimites.length - 1) {
        val = [
          {
            yAxis: settings.glucoseLimites[i].low / 18,
            xAxis: new Date(new Date().setHours(0, 0, 0, 0) + settings.glucoseLimites[i].st * 60 * 1000)
          },
          {
            yAxis: settings.glucoseLimites[i].high / 18,
            xAxis: new Date(new Date().setHours(0, 0, 0, 0) + 86400000)

          }
        ]
      } else {
        val = [
          {
            yAxis: settings.glucoseLimites[i].low / 18,
            xAxis: new Date(new Date().setHours(0, 0, 0, 0) + settings.glucoseLimites[i].st * 60 * 1000)
          },
          {
            yAxis: settings.glucoseLimites[i].high / 18,
            xAxis: new Date(new Date().setHours(0, 0, 0, 0) + settings.glucoseLimites[i + 1].st * 60 * 1000)
          }
        ]
      }
      areaData.items.push(val)
    }
  } else {
    let target_Low = Number(sessionStorage.getItem('target_Low')) || 3.9
    let target_High = Number(sessionStorage.getItem('target_High')) || 10
    let val = [
      {
        yAxis: target_Low / 18,
        xAxis: new Date(new Date(new Date().setHours(0, 0, 0, 0)))
      },
      {
        yAxis: target_High / 18,
        xAxis: new Date(new Date().setHours(0, 0, 0, 0) + 86400000)
      }
    ]
    areaData.items.push(val)
  }
}
function getSgLim(sg, data, patient_info, id, item) {
  let flag = auth.isNoCalib(item)
  if (flag) {
    let time = new Date().getTime() / 1000
    let val = {
      type: '待校准',
      id: item.id,
      ward: patient_info.ward,
      bed: patient_info.bed,
      name: patient_info.name,
      sg: '',
      time: time
    }
    commit('ADD_ALERT', val)
  } else {
    let time = new Date().getTime() / 1000
    for (let i = 0; i < data.length; i++) {
      if (time >= data[i][0].xAxis.getTime() / 1000 && time <= data[i][1].xAxis.getTime() / 1000) {
        if (sg > data[i][1].yAxis && auth.getAlertFlag(item, 'high')) {
          //高报警
          let val = {
            type: '血糖偏高',
            id: id,
            ward: patient_info.ward,
            bed: patient_info.bed,
            name: patient_info.name,
            sg: sg.toFixed(2),
            time: time
          }

          commit('ADD_ALERT', val)

        } else if (sg < data[i][0].yAxis && sg >= 3.1 && auth.getAlertFlag(item, 'low')) {
          //低报警
          let val = {
            type: '血糖偏低',
            id: id,
            bed: patient_info.bed,
            ward: patient_info.ward,
            name: patient_info.name,
            sg: sg.toFixed(2),
            time: time
          }

          commit('ADD_ALERT', val)
        } else if (sg < data[i][0].yAxis && sg < 3.1 && auth.getAlertFlag(item, 'verylow')) {
          //低报警
          let val = {
            type: '低血糖',
            id: id,
            bed: patient_info.bed,
            ward: patient_info.ward,
            name: patient_info.name,
            sg: sg.toFixed(2),
            time: time
          }

          commit('ADD_ALERT', val)
        } else {
          let val = {
            id: id,
            type: '血糖正常',
            ward: patient_info.ward,
            bed: patient_info.bed,
            name: patient_info.name,
            time: time
          }

          commit('ADD_ALERT', val)
        }
      }
    }
  }

}


onMounted(() => {
  // isTimeOut();
  getPatienList()
  getAlerm()
  clearInterval(Number(timer2.value));
  clearInterval(Number(timer3.value));
  timer2.value = setInterval(() => {
    getPatienList()
   
  }, 1000 * 20)
  timer3.value =setInterval(() => {
    getAlerm()
  }, 1000 * 5)
});

onUnmounted(() => {
  // 最后一步，离开页面的时候，清除一下定时器，也解绑点击事件
  clearInterval(Number(timer));
  clearInterval(Number(timer2.value));
  clearInterval(Number(timer3.value));
  window.removeEventListener("click", () => { return }, true);
});
getSettings()
</script>

<style scoped>
.routes {
  z-index: 999;
  position: relative;
  opacity: 0.8;
}

.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  background: #fff;
}

.content {
  background: rgb(250, 250, 250);
  display: flex;
  flex-flow: column;
  height: 100%;

}

.top {
  width: 100%;
  background: rgb(247, 247, 247);
  border-right: 1px solid #ccc;
}

.middle {
  display: flex;
  flex: 1;
}

.left {
  height: 100%;
  width: 288px;
  background: #fff;
}

.right {
  display: flex;
  flex: 1;
  overflow-y: auto;
}
</style>
<style>
.custom-notification {
  z-index: 9999 !important;
  margin-top: 0 !important;
  bottom: 16px !important;
  padding: 10px 20px;
}

.el-notification__group {
  margin-bottom: 0 !important;
}
</style>