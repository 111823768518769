function getAvgSD(data) {
    if (!(data instanceof Array) || data.length == 0) {
        return {
            'sd': 0,
            'avg': 0,
            'num': 0
        }
    }
    let size = data.length;
    let total = 0;
    let avg = 0;
    //let diff=[];
    let diffTotal = 0;
    data.map(function (e, i) {
        total += e;
    })
    avg = total / size;
    data.map(function (e, i) {
        diffTotal += Math.pow((e - avg), 2);
    })
    let sd = Math.sqrt(diffTotal / size);
    return {
        'sd': sd,
        'avg': avg,
        'num': size,
    }
}

function getDashboardSg(isMg, ts, val) {
    let sg1 = new Array(144),
        sg2 = new Array(144),

        length1 = val.length,
        sgHalf = new Array(144);
    for (let i = 0; i < 144; i++) {
        sg1[i] = [];
        sg2[i] = [];
    }
    //将属于半个小时内的数据集合在一起
    for (let i = 0; i < length1; i++) {
        let length2 = val[i].length,
            st = ts[i][0];
        if (length2 > 0) {
            val[i].map(function (e, i) {
                if ((e[4] === 'C' || e[4] === 'CX' || e[4] === 'NC') && e[3] > 0.0001 &&
                    e[1] >= st) {
                    let count = Math.floor(parseInt(e[1] - st) / 600);
                    if (count >= 144 || count < 0)
                        return;
                    e[3] = (e[3] > 22.2) ? 22.2 : e[3];
                    e[3] = (e[3] < 2.2) ? 2.2 : e[3];
                    sg1[count].push(e[3]);
                }
            })
        }
    }

    //排序并找出对应位置的值
    sg1.map(function (e, i) {
        if (e.length > 0) {
            e.sort(compare);
            let length3 = e.length;
            let d1 = Math.floor(0.05 * length3);
            let d2 = Math.floor(0.25 * length3);
            let d3 = Math.floor(0.5 * length3);
            let d4 = Math.floor(0.75 * length3);
            let d5 = Math.floor(0.95 * length3);
            sg2[i].push(e[0]);
            sg2[i].push(e[d1]);
            sg2[i].push(e[d2]);
            sg2[i].push(e[d3]);
            sg2[i].push(e[d4]);
            sg2[i].push(e[d5]);
            sg2[i].push(e[length3 - 1]);
            sgHalf[i] = (isMg) ? Math.round(e[d3] * 18) : parseFloat(e[d3].toFixed(1));
        }
    });
    let YDataMin = new Array(144);
    let YData10P = new Array(144);
    let YDataQ1 = new Array(144);
    let YDataMed = new Array(144);
    let YDataQ3 = new Array(144);
    let YData90P = new Array(144);
    let YDataMax = new Array(144);
    let sel_time = new Date(2019, 0, 1).getTime();

    // 堆叠图需要的差值
    let sg3 = sg2.map(function (e, i) {
        if (e && e.length > 0) {
            let d0 = (isMg) ? Math.round(e[0] * 18) : parseFloat(e[0].toFixed(1));
            let d1 = (isMg) ? Math.round((e[1] - e[0]) * 18) : parseFloat((e[1] - e[0]).toFixed(1));
            let d2 = (isMg) ? Math.round((e[2] - e[1]) * 18) : parseFloat((e[2] - e[1]).toFixed(1));
            let d3 = (isMg) ? Math.round((e[3] - e[2]) * 18) : parseFloat((e[3] - e[2]).toFixed(1));
            let d4 = (isMg) ? Math.round((e[4] - e[3]) * 18) : parseFloat((e[4] - e[3]).toFixed(1));
            let d5 = (isMg) ? Math.round((e[5] - e[4]) * 18) : parseFloat((e[5] - e[4]).toFixed(1));
            let d6 = (isMg) ? Math.round((e[6] - e[5]) * 18) : parseFloat((e[6] - e[5]).toFixed(1));

            let v0 = (isMg) ? Math.round(e[0] * 18) : parseFloat(e[0].toFixed(1));
            let v1 = (isMg) ? Math.round((e[1]) * 18) : parseFloat((e[1]).toFixed(1));
            let v2 = (isMg) ? Math.round((e[2]) * 18) : parseFloat((e[2]).toFixed(1));
            let v3 = (isMg) ? Math.round((e[3]) * 18) : parseFloat((e[3]).toFixed(1));
            let v4 = (isMg) ? Math.round((e[4]) * 18) : parseFloat((e[4]).toFixed(1));
            let v5 = (isMg) ? Math.round((e[5]) * 18) : parseFloat((e[5]).toFixed(1));
            let v6 = (isMg) ? Math.round((e[6]) * 18) : parseFloat((e[6]).toFixed(1));


            YDataMin[i] = [sel_time + i * 10 * 60 * 1000 + 5 * 60 * 1000, d0, v0];
            YData10P[i] = [sel_time + i * 10 * 60 * 1000 + 5 * 60 * 1000, d1, v1];
            YDataQ1[i] = [sel_time + i * 10 * 60 * 1000 + 5 * 60 * 1000, d2, v2];
            YDataMed[i] = [sel_time + i * 10 * 60 * 1000 + 5 * 60 * 1000, d3, v3];
            YDataQ3[i] = [sel_time + i * 10 * 60 * 1000 + 5 * 60 * 1000, d4, v4];
            YData90P[i] = [sel_time + i * 10 * 60 * 1000 + 5 * 60 * 1000, d5, v5];
            YDataMax[i] = [sel_time + i * 10 * 60 * 1000 + 5 * 60 * 1000, d6, v6];

            return [d0, d1, d2, d3, d4, d5, d6]

        }
    });
    return {
        YDataMin,
        YData10P,
        YDataQ1,
        YDataMed,
        YDataQ3,
        YData90P,
        YDataMax
    }


}

function compare(value1, value2) {
    return value1 - value2;
}

function calculateCRC(data) {
    let crc = 0; // 初始值
    for (let j = 0; j < data.length; j++) {
        crc ^= data[j]; // 对当前CRC值和data数组的当前字节进行异或操作
        for (let i = 0; i < 8; i++) {
            if ((crc & 1) > 0) {
                // 如果CRC的最低位是1，则右移一位并与0x0C（二进制为00001100）进行异或操作
                crc = (crc >> 1) ^ 0x0C;
            } else {
                // 如果CRC的最低位是0，则只进行右移一位操作
                crc = crc >> 1;
            }
        }
    }
    return crc; // 返回计算得到的CRC值
}

function checkSensorCode(sensorCode) {
    let code = sensorCode;
    let a = code & 0xfffc; // 清零低两位

    let low = a & 0xff; // 提取低8位
    let high = (a >>> 8) & 0xff; // 无符号右移8位，提取高8位

    // 假设我们有一个calculateCRC4函数来计算CRC，这里用占位符代替
    let crc = calculateCRC([high, low]);
    let result = (crc + 1) & 0x3; // 获取CRC结果的低两位

    // 检查result是否与sensorCode的低两位相等
    return result === (sensorCode & 0x03);
}

//显示免校准图标
function showSensorCodeIcon(patientData) {
    if (!patientData.setting) {
        return false
    }
    if (!patientData || patientData.state == 'out' || patientData.setting.connect_sensor != 'Y') {
        return false
    }
    if (patientData.ispro) {
        return false
    }
    //可显示的状态
    let arr = [0, 1, 2, 3, 8, 9, 10, 11, 12]
    if (patientData == null) {
        return false
    }
    //出院了
    if (patientData.state == 'out') {
        return false
    }
    if (patientData.status) {
        //是否连接中
        if (patientData.status.isConnected == false) {
            return false
        }

        if (arr.indexOf(patientData.status.status) == -1) {
            return false
        } else {
            let peerState = getState(patientData.status.status)
            if (patientData.sg.length > 0) {
                let time1 = patientData.sg[patientData.sg.length - 1][1]
                let timeDiff = (new Date().getTime()) / 1000 - time1
                if (timeDiff > 3600) {
                    return false
                } else if (timeDiff > 1800) {
                    if (peerState != 'Fail' && peerState != 'Overdue' && peerState != 'Charging' && peerState != 'ChargeFinish' && peerState != 'TransmitterBatteryEmpty' && peerState != 'TransmitterError') {
                        return false
                    } else {
                        if (patientData.setting.sensorCodeEnable == true) {
                            return true
                        } else {
                            return false
                        }
                    }
                } else {
                    if (patientData.setting.sensorCodeEnable == true) {
                        return true
                    } else {
                        return false
                    }
                }
            }
        }
    }
    return false
}
// 显示血糖值
function showGlucoseStatus(patientData) {
    //先判断出院，无信息 未连接
    if (!patientData.setting) {
        return '--'
    }
    if (!patientData || patientData.state == 'out' || patientData.setting.connect_sensor != 'Y') {
        return '--'
    }
    if (!patientData.status || Object.keys(patientData.status).length === 0) {
        return '--'
    }

    //时间判断
    let ti = new Date().getTime() / 1000
    let t2 = patientData.status.updateTime
    let time = (ti - t2) / 60
    if (time >= 180) {
        return '--'
    }

    let peerState = getState(patientData.status.status)
    if (time >= 30 && time < 180) {
        if (peerState != 'Fail' && peerState != 'Overdue' && peerState != 'Charging' && peerState != 'ChargeFinish' && peerState != 'TransmitterBatteryEmpty' && peerState != 'TransmitterError') {
            peerState = 'Lost'
        }
    }
    let glucose = patientData.status.glucose
    let setText
    if (patientData.status.glucose == 0 && patientData.status.current == 0 && peerState == 'CalibrateOverdue') {
        if (patientData.status.sequence > 720) {
            peerState = 'Stable'
        } else {
            peerState = 'NoCalibrate'
        }
    }
    if (glucose == 0) {
        if (peerState == 'Stable') {

        } else {
            setText = '--'
            return setText
        }

    } else {
        setText = glucose
    }
    if ((peerState != 'NoCalibrate') && (peerState != 'CalibrateOverdue') && (peerState != 'Stable') && (peerState != 'Lost')) {
        return '--'
    } else if (peerState == 'Lost' && time > 180) {
        return '--'
    } else {
        return setText
    }
}
// 发射器连接状态显示
function showTransmitterStatus(patientData) {
    //先判断出院，无信息 未连接

    if (!patientData.setting) {
        return '--'
    }
    if (!patientData || patientData.state == 'out' || patientData.setting.connect_sensor != 'Y') {
        return '--'
    }
    if (!patientData.status || Object.keys(patientData.status).length === 0) {
        return '--'
    }
    //时间判断
    let ti = new Date().getTime() / 1000
    let t2 = patientData.status.updateTime
    let time = (ti - t2) / 60
    if (time >= 180) {
        return '--'
    }
    let peerState = getState(patientData.status.status)
    if (time >= 30 && time < 180) {
        if (peerState != 'Fail' && peerState != 'Overdue' && peerState != 'Charging' && peerState != 'ChargeFinish' && peerState != 'TransmitterBatteryEmpty' && peerState != 'TransmitterError') {
            return '传感器丢失'
        }
    }


    if (patientData.status.glucose == 0 && patientData.status.current == 0 && peerState == 'CalibrateOverdue') {
        if (patientData.status.sequence > 720) {
            peerState = 'Stable'
        } else {
            peerState = 'NoCalibrate'
        }
    }
    if (peerState == 'CalibrateOverdue') {
        peerState = 'Stable';
    }
    if (peerState == 'None') {
        return '--'
    } else if (peerState == 'Lost') {
        return '传感器丢失'
    } else if (peerState == 'TransmitterError') {
        return '发射器故障'
    } else if (peerState == 'TransmitterNeedCharge') {
        return 'Transmitter \nNeed Charge'
    } else if (peerState == 'ChargeFinish') {
        return '充电完成'
    } else if (peerState == 'Charging') {
        return '充电中'
    } else if (peerState == 'Overdue') {
        return '传感器过期'
    } else if (peerState == 'Error') {
        return '无血糖读数'
    } else if (peerState == 'Fail') {
        return '请更换传感器'
    } else if (peerState == 'Calibrating') {
        return '校准中'
    } else if (peerState == 'Stable') {
        return '正常监测中'
    } else if (peerState == 'CalibrateOverdue') {
        return '校准过期'
    } else if (peerState == 'CalibrateError0' || peerState == 'CalibrateError1') {
        return '校准错误'
    } else if (peerState == 'NoCalibrate') {
        return '未校准'
    } else if (peerState == 'Hydration' || peerState == 'Transition') {
        return '正在初始化...'
    } else if (peerState == 'Setup') {
        return '传感器连接中'
    } else {
        return peerState
    }
}
//显示校准图表
function showCalibIcon(patientData) {
    if (!patientData.setting) {
        return '--'
    }
    if (!patientData || patientData.state == 'out' || patientData.setting.connect_sensor != 'Y') {
        return '--'
    }
    if (!patientData.status || Object.keys(patientData.status).length === 0) {
        return '--'
    }
    if (patientData.ispro) {
        return 'icon-icon_cloud'
    }
    //时间判断
    let ti = new Date().getTime() / 1000
    let t2 = patientData.status.updateTime
    let time = (ti - t2) / 60
    if (time >= 180) {
        return '--'
    }
    let peerState = getState(patientData.status.status)
    if (time >= 30 && time < 180) {
        if (peerState != 'Fail' && peerState != 'Overdue' && peerState != 'Charging' && peerState != 'ChargeFinish' && peerState != 'TransmitterBatteryEmpty' && peerState != 'TransmitterError') {
            peerState = 'Lost'
        }
    }
    if (peerState == null) {
        return '--'
    }
    if (!showCalibrate(peerState)) {
        return '--'
    }
    let sk = patientData.status.nextSequenceNeedCalibrate - patientData.status.sequence
    if (sk <= 0) {
        return 'icon-icon_calibrate_07'
    }
    if (sk > 300) {
        return 'icon-icon_calibrate_01'
    } else if (sk > 240) {
        return 'icon-icon_calibrate_02'
    } else if (sk > 180) {
        return 'icon-icon_calibrate_03'
    } else if (sk > 120) {
        return 'icon-icon_calibrate_04'
    } else if (sk > 60) {
        return 'icon-icon_calibrate_05'
    } else {
        if (patientData.status.nextSequenceNeedCalibrate > 900) {
            return 'icon-icon_calibrate_01'
        }
        return 'icon-icon_calibrate_06'
    }

}

function isNoCalib(patientData) {

    // 是否未校准
    if (!patientData.setting) {
        return false
    }
    if (!patientData || patientData.state == 'out' || patientData.setting.connect_sensor != 'Y') {
        return false
    }
    if (!patientData.status || Object.keys(patientData.status).length === 0) {
        return false
    }
    //时间判断
    let ti = new Date().getTime() / 1000
    let t2 = patientData.status.updateTime
    let time = (ti - t2) / 60
    if (time >= 180) {
        return false
    }
    let peerState = getState(patientData.status.status)
    if (time >= 30 && time < 180) {
        if (peerState != 'Fail' && peerState != 'Overdue' && peerState != 'Charging' && peerState != 'ChargeFinish' && peerState != 'TransmitterBatteryEmpty' && peerState != 'TransmitterError') {
            peerState = 'Lost'
        }
    }
    if (patientData.status.glucose == 0 && patientData.status.current == 0 && peerState == 'CalibrateOverdue') {
        if (patientData.status.sequence > 720) {
            peerState = 'Stable'
        } else {
            peerState = 'NoCalibrate'
        }
    }
    if (peerState == null) {
        return false
    }
    if (peerState == 'NoCalibrate') {
        return true
    }
    // let sk = patientData.status.nextSequenceNeedCalibrate - patientData.status.sequence
    // if (sk <= 0) {
    //     return true
    // }
    return false
}

function isAbnormal(patientData) {
    if (!patientData.setting) {
        return false
    }
    if (!patientData || patientData.state == 'out' || patientData.setting.connect_sensor != 'Y') {
        return false
    }
    if (!patientData.status || Object.keys(patientData.status).length === 0) {
        return false
    }
    //时间判断
    let ti = new Date().getTime() / 1000
    let t2 = patientData.status.updateTime
    let time = (ti - t2) / 60
    if (time >= 180) {
        return false
    }

    let peerState = getState(patientData.status.status)
    if (time >= 30 && time < 180) {
        if (peerState != 'Fail' && peerState != 'Overdue' && peerState != 'Charging' && peerState != 'ChargeFinish' && peerState != 'TransmitterBatteryEmpty' && peerState != 'TransmitterError') {
            peerState = 'Lost'
        }
    }
    //判断数据类型

    if (peerState == 'Lost' || peerState == 'Error' || peerState == 'TransmitterError' || peerState == 'Fail' || peerState == 'Overdue') {
        return true
    }
    return false
}
//显示血糖箭头
function showCgmRate(patientData) {

    if (!patientData.status || Object.keys(patientData.status).length === 0) {
        return '--'
    }
    if (!patientData.setting) {
        return '--'
    }
    if (!patientData || patientData.state == 'out' || patientData.setting.connect_sensor != 'Y') {
        return '--'
    }
    let ti = new Date().getTime() / 1000
    let t2 = patientData.status.updateTime
    let time = (ti - t2) / 60
    let peerState = getState(patientData.status.status)
    if (time >= 30 && time < 180) {
        if (peerState != 'Fail' && peerState != 'Overdue' && peerState != 'Charging' && peerState != 'ChargeFinish' && peerState != 'TransmitterBatteryEmpty' && peerState != 'TransmitterError') {
            peerState = 'Lost'
        }
    }
    if (time >= 180) {
        return '--'
    }
    if (peerState == null) {
        return '--'
    }
    if (!showIcon(peerState)) {
        return '--'
    }
    if (patientData.status) {
        switch (patientData.status.glucoseRate) {
            case 0:
                return 'icon-icon_state_01'
                break
            case 1:
                return 'icon-icon_state_02'
                break
            case 2:
                return 'icon-icon_state_04'
                break
            case 3:
                return 'icon-icon_state_06'
                break
            case 4:
                return 'icon-icon_state_03'
                break
            case 5:
                return 'icon-icon_state_05'
                break
            case 6:
                return 'icon-icon_state_07'
                break
            case 8:
                return 'icon-icon_state_01'
                break
        }
    }
    return '--'
}
//显示寿命
function showLife(patientData) {
    if (!patientData.status || Object.keys(patientData.status).length === 0) {
        return '--'
    }
    let ti = new Date().getTime() / 1000
    let t2 = patientData.status.updateTime
    let time = (ti - t2) / 60
    let peerState = getState(patientData.status.status)
    if (time >= 30 && time < 180) {
        if (peerState != 'Fail' && peerState != 'Overdue' && peerState != 'Charging' && peerState != 'ChargeFinish' && peerState != 'TransmitterBatteryEmpty' && peerState != 'TransmitterError') {
            peerState = 'Lost'
        }
    }
    if (time >= 180) {
        return '--'
    }
    if (peerState == null) {
        return '--'
    }
    if (!showCalibrate(peerState)) {
        return '--'
    }
    if (patientData.status.sensorLifetimeTotalCount) {
        //当前序号乘于2
        return getMonitorTime(patientData.status.sequence * 2)
    }
}
//显示血糖时间间隔
function showUpdateRange(patientData) {
    if (!patientData.setting) {
        return '--'
    }
    if (!patientData || patientData.state == 'out' || patientData.setting.connect_sensor != 'Y') {
        return '--'
    }
    if (!patientData.status || Object.keys(patientData.status).length === 0) {
        return false
    }
    //时间判断
    let ti = new Date().getTime() / 1000
    let t2 = patientData.status.updateTime
    let time = (ti - t2) / 60
    if (time >= 180) {
        return false
    }


    return true
}

// 计算剩余时间
function getWarmLeftTimeStr(patientData) {
    
    if (!patientData.status || Object.keys(patientData.status).length === 0) {
        return '--'
    }
    let sensorWarmupCount = patientData.status.sensorWarmupCount
    if (patientData.status.deviceType != null && patientData.status.deviceType == "136") {
        sensorWarmupCount = 15
    }
    switch (patientData.status.deviceType) {
        case 'TG158':
        case 'TG160':
        case 'TG180':
            sensorWarmupCount = 15
            break;
        case 'TY012':
        case 'TY015':
        case 'TY025': 
        case 'TY026': 
        case 'TY027': 
        case 'TY008':
        case 'TY015_2':
            sensorWarmupCount = 60
            break;
    }
    let time = (sensorWarmupCount - patientData.status.sequence) * 2
    return '剩余' + Math.floor(time / 60) + '时' + time % 60 + '分钟'

}

function getState(status) {
    let arr = ['Setup', 'Transition', 'Hydration', 'Stable', 'TransmitterError', 'Fail', 'Overdue', 'Lost', 'NoCalibrate',
        'Calibrating', 'CalibrateOverdue', 'CalibrateError0', 'CalibrateError1', 'Charging', 'ChargeFinish', 'Error',
        'TransmitterNeedCharge', 'TransmitterBatteryEmpty', 'None'
    ]
    if (status !== null) {
        return arr[status]
    }
}

function getWarmPercent(patientData) {
    
    let sensorWarmupCount = patientData.status.sensorWarmupCount
    if (patientData.status.deviceType != null && patientData.status.deviceType == "136") {
        sensorWarmupCount = 15
    }
    switch (patientData.status.deviceType) {
        case 'TG158':
        case 'TG160':
        case 'TG180':
            sensorWarmupCount = 15
            break;
        case 'TY012':
        case 'TY015':
        case 'TY025': 
        case 'TY026': 
        case 'TY027': 
        case 'TY008':
        case 'TY015_2':
            sensorWarmupCount = 60
            break;
    }
    if (sensorWarmupCount) {
        let percent = (patientData.status.sequence * 100) / sensorWarmupCount
        return percent
    } else {
        return 0
    }

}

function getMonitorTime(val) {
    if (val < 0) {
        return '--';
    } else {
        let d = parseInt(val / 1440);
        let h = parseInt(val % 1440 / 60);
        let mi = parseInt(val % 60);
        if (d === 0) {
            return '已使用' + h + ' 小时 ' + mi + ' 分钟'
        } else {
            return '已使用' + d + ' 天 ' + h + ' 小时 ' + mi + ' 分钟'
        }
    }
}

function showCalibrate(state) {
    if (state == 'Stable' || state == 'CalibrateOverdue' || state == 'CalibrateError0' || state == 'CalibrateError1' || state == 'Calibrating' || state == 'NoCalibrate') {
        return true;
    }
    return false;
}

function showIcon(state) {
    if (state == 'Stable' || state == 'CalibrateOverdue' || state == "Calibrating") {
        return true;
    }
    return false;
}


// 对科室有无编辑权限
function isEditAccount() {

    let item = sessionStorage.getItem('department_id')
    let authority = JSON.parse(sessionStorage.getItem('authority'))
    if (authority.department && authority.department.length > 0) {
        for (let i = 0; i < authority.department.length; i++) {
            if (authority.department[i].check == true && item == authority.department[i].id && authority.department[i].edit == true) {
                return true
            }
        }
    }
    return false
}

function isSgShowType(data) {
    
    let sgShow = showGlucoseStatus(data)
    sgShow = typeof sgShow === 'number' ? sgShow.toFixed(1) : sgShow
    let areaData = getSetting(data)
    let time = new Date().getTime() / 1000;
    if (sgShow) {
        
        if (areaData.length > 0) {
            for (let i = 0; i < areaData.length; i++) {
                if (time >= areaData[i][0].xAxis.getTime() / 1000 && time <= areaData[i][1].xAxis.getTime() / 1000) {      
                    if (Number(sgShow) > areaData[i][1].yAxis&&getAlertFlag(data,'high')) {
                        return 'high'
                    } else if (Number(sgShow) < areaData[i][0].yAxis) {
                        if (Number(sgShow) < 3.1&&getAlertFlag(data,'verylow')) {
                            return 'very low'
                        } else if(Number(sgShow) >= 3.1&&getAlertFlag(data,'low')){
                            return 'low'
                        }
                    }
                }
            }
        }
        return ''
    } else {
        return ''
    }

}
function getAlertFlag(data,type){
    let settings = data.setting
    if(settings &&settings.alarmEnable&&settings.alarmLowEnable&&type=='low'){
        return true
    }
    else if(settings&&settings.alarmEnable &&settings.alarmHighEnable&&type=='high'){
        return true
    }else if(settings&&settings.warningAlarmEnable&&type=='verylow'){
        return true
    }else{
        return false
    }
}
function getSetting(data) {
    let settings = data.setting
    let areaData = []
    let target_Low
    let target_High
    if (settings && settings.glucoseLimites && settings.glucoseLimites.length > 0) {
        for (let i = 0; i < settings.glucoseLimites.length; i++) {
            let val
            if (i == settings.glucoseLimites.length - 1) {
                val = [{
                        yAxis: settings.glucoseLimites[i].low / 18,
                        xAxis: new Date(new Date().setHours(0, 0, 0, 0) + settings.glucoseLimites[i].st * 60 * 1000)
                    },
                    {
                        yAxis: settings.glucoseLimites[i].high / 18,
                        xAxis: new Date(new Date().setHours(0, 0, 0, 0) + 86400000)

                    }
                ]
            } else {
                val = [{
                        yAxis: settings.glucoseLimites[i].low / 18,
                        xAxis: new Date(new Date().setHours(0, 0, 0, 0) + settings.glucoseLimites[i].st * 60 * 1000)
                    },
                    {
                        yAxis: settings.glucoseLimites[i].high / 18,
                        xAxis: new Date(new Date().setHours(0, 0, 0, 0) + settings.glucoseLimites[i + 1].st * 60 * 1000)
                    }
                ]
            }
            areaData.push(val)
        }
        const maxIndex = settings.glucoseLimites.reduce((maxIdx, obj, idx) => (obj.high > settings.glucoseLimites[maxIdx].high ? idx : maxIdx), 0);
        const minIndex = settings.glucoseLimites.reduce((maxIdx, obj, idx) => (obj.low < settings.glucoseLimites[maxIdx].low ? idx : maxIdx), 0);
        target_Low = settings.glucoseLimites[minIndex].low || 3.9
        target_High = settings.glucoseLimites[maxIndex].high || 10
    } else {
        target_Low = Number(sessionStorage.getItem('target_Low')) || 3.9
        target_High = Number(sessionStorage.getItem('target_High')) || 10
        let val = [{
                yAxis: target_Low / 18,
                xAxis: new Date(new Date(new Date().setHours(0, 0, 0, 0)))
            },
            {
                yAxis: target_High / 18,
                xAxis: new Date(new Date().setHours(0, 0, 0, 0) + 86400000)
            }
        ]
        areaData.push(val)
    }
    return areaData
}
export default {
    getAvgSD,
    getDashboardSg,
    compare,
    calculateCRC,
    checkSensorCode,
    showSensorCodeIcon,
    showTransmitterStatus,
    showCalibIcon,
    showCgmRate,
    showLife,
    showCalibrate,
    showIcon,
    showGlucoseStatus,
    getState,
    showUpdateRange,
    getWarmLeftTimeStr,
    getWarmPercent,
    isEditAccount,
    isNoCalib,
    isAbnormal,
    isSgShowType,
    getAlertFlag
}