<template>
    <div class="main">
        <div class="icon">
            <div class="icon-home_logo"></div>
            <div class="title">
                <span>{{ hosName }}</span>
            </div>
        </div>
        <div class="title">
            <span>{{ patientName }}</span>
        </div>
        <div class="logout">

            <div v-if="flag">
                <el-button @click="toEnd" v-if="flag2">出院</el-button>

                <el-button @click="toprint">打印报表</el-button>
            </div>
            <el-dropdown style="margin-left: 30px;">
                <span class="el-dropdown-link">
                    更多
                    <el-icon class="el-icon--right"><arrow-down /></el-icon>
                </span>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item @click="toDelete" v-if="flag && flag4 && falg3">删除</el-dropdown-item>
                        <el-dropdown-item @click="toStop"
                            v-if="flag && flag4 && flag5 && falg3&&flag2">暂停监控</el-dropdown-item>
                        <el-dropdown-item @click="toRec"
                            v-if="flag && flag4 && !flag5 && falg3&&flag2 ">恢复监控</el-dropdown-item>
                        <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
    </div>
</template>

<script setup>
import auth from '../methods/auth'
import { defineComponent, computed, getCurrentInstance, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ElMessageBox, ElMessage } from 'element-plus'
import { ElNotification } from 'element-plus'

const { state, commit } = useStore()
const router = useRouter()
let sessionName = sessionStorage.getItem('userAddId')

const instance = getCurrentInstance()
let hosName = sessionStorage.getItem('hospital_name')


let name = computed(() => {
    return state.name
})
//判断处于患者管理页面
let flag = computed(() => {
    console.log(router.currentRoute.value.path)
    let path = router.currentRoute.value.path
    if (path == '/RealTimeMonitor' || path == '/patientMessage' || path == '/PersonalSettings' || path == '/EventRecord' || path == '/AddEventRecord' || path == '/editEventRecord') {
        return true
    }
    return false
})
//判断是否出院
let flag2 = computed(() => {
    let path = router.currentRoute.value.path
    let role = sessionStorage.getItem('role')
    let flagDel = sessionStorage.getItem('delFlag')
    if (path == '/RealTimeMonitor' || path == '/patientMessage' || path == '/PersonalSettings' || path == '/EventRecord' || path == '/AddEventRecord' || path == '/editEventRecord') {
        let status = sessionStorage.getItem('status')
        if (flagDel == '2') {
            return false
        }
        if (role == '2') {
            if (status == 'out' || !auth.isEditAccount()) {
                return false
            } else {
                return true
            }
        } else {
            if (status == 'out') {
                return false
            } else {
                return true
            }
        }
    }
    return false
})
// 判断患者是否已删除
let falg3 = computed(() => {
    console.log(router.currentRoute.value.path)
    let path = router.currentRoute.value.path
    let flagDel = sessionStorage.getItem('delFlag')
    if (path == '/RealTimeMonitor' || path == '/patientMessage' || path == '/PersonalSettings' || path == '/printpropare' || path == '/EventRecord' || path == '/AddEventRecord' || path == '/editEventRecord') {
        if(flagDel!=2){
            return true
        }
    }
    return false
})
// 判断是否有编辑权限
let flag4 = computed(() => {
    console.log(router.currentRoute.value.path)
    let role = sessionStorage.getItem('role')
    let path = router.currentRoute.value.path
    if (path == '/RealTimeMonitor' || path == '/patientMessage' || path == '/PersonalSettings' || path == '/EventRecord' || path == '/AddEventRecord' || path == '/editEventRecord') {
        if (role == '2') {
            if (auth.isEditAccount()) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }
    return false
})
//判断处于连接中才可以
let flag5 = computed(() => {
    console.log(router.currentRoute.value.path)
    let path = router.currentRoute.value.path
    if (path == '/RealTimeMonitor' || path == '/patientMessage' || path == '/PersonalSettings' || path == '/EventRecord' || path == '/AddEventRecord' || path == '/editEventRecord') {
        let id = sessionStorage.getItem('patient_id')
        let json = sessionStorage.getItem('patienList') || ''
        let patienList = JSON.parse(json)
        let patientData
        for (let i = 0; i < patienList.length; i++) {
            if (patienList[i].id == id) {
                patientData = patienList[i]
            }
        }
        if (patientData && patientData.setting) {
            if (patientData.setting.connect_sensor == 'Y') {
                return true
            } else if (patientData.setting.connect_sensor == 'N') {
                return false
            }
        }
    }

})
let patientName = ref('')
function getPatienList() {
    let val = {
        st: new Date().getTime(),
        hospital_id: 1,
        get_delete_flag: false,
        add_sg: true
    }
    instance.proxy.$http.post('/api/v2.1/center/patient/get_list', val).then(res => {
        let error = res.error
        if (error == 0) {
            let arrPhone = []
            if(res.authority){
                sessionStorage.setItem('authority', JSON.stringify(res.authority))
            }
            for (let i = 0; i < res.patient.length; i++) {
                if (res.patient[i].easyview_uid && res.patient[i].mobile_number) {
                    arrPhone.push(res.patient[i].mobile_number)
                }
            }
            let valPhone = {
                "username_list": arrPhone
            }
            instance.proxy.$http.post('/api/v2.1/center/easyview_proxy/get_sensor_status', valPhone).then(res2 => {
                if (res.error == 0) {
                    let obj = res2.data
                    for (let k = 0; k < res.patient.length; k++) {
                        let phoneNumer = res.patient[k].mobile_number
                        let easyview_uid = res.patient[k].easyview_uid
                        let sn = res.patient[k].transmitter_sn
                        if (phoneNumer && easyview_uid && obj[phoneNumer] && obj[phoneNumer].sensor_status && obj[phoneNumer].sensor_status.updateTime&&sn==Number(obj[phoneNumer].sensor_status.serial).toString(16).padStart(8, '0').toLocaleUpperCase()) {
                            res.patient[k].sg.push(...obj[phoneNumer].sg)
                            for(let m=0;m<obj[phoneNumer].bg.length;m++){
                            let item =[
                                    obj[phoneNumer].bg[m][2],
                                    obj[phoneNumer].bg[m][0],
                                    '',
                                    obj[phoneNumer].bg[m][1],
                                    ''
                                ]
                                res.patient[k].bg.push(item)
                            }
                            if (!res.patient[k].status.updateTime) {
                                res.patient[k].status = obj[phoneNumer].sensor_status
                                res.patient[k].ispro = true
                            } else if (obj[phoneNumer].sensor_status.updateTime >= res.patient[k].status.updateTime) {
                                res.patient[k].status = obj[phoneNumer].sensor_status
                                res.patient[k].ispro = true
                            }
                        }
                    }
                    sessionStorage.setItem('patienList', JSON.stringify(res.patient))

                    let path = router.currentRoute.value.path
                    if (path == '/RealTimeMonitor' || path == '/patientMessage' || path == '/PersonalSettings' || path == '/EventRecord' || path == '/AddEventRecord' || path == '/editEventRecord') {
                        location.reload()
                    }
                }
            })
            //新增一个函数来处理预警相关功能
            // commit('clear_Alert',null)

        }
    })
}
function getPatienListDel() {
    let val = {
        st: new Date().getTime(),
        hospital_id: 1,
        get_delete_flag: false,
        add_sg: true
    }
    instance.proxy.$http.post('/api/v2.1/center/patient/get_list', val).then(res => {
        let error = res.error
        if (error == 0) {
            let arrPhone = []
            if(res.authority){
                sessionStorage.setItem('authority', JSON.stringify(res.authority))
            }
            for (let i = 0; i < res.patient.length; i++) {
                if (res.patient[i].easyview_uid && res.patient[i].mobile_number) {
                    arrPhone.push(res.patient[i].mobile_number)
                }
            }
            let valPhone = {
                "username_list": arrPhone
            }
            instance.proxy.$http.post('/api/v2.1/center/easyview_proxy/get_sensor_status', valPhone).then(res2 => {
                if (res.error == 0) {
                    let obj = res2.data
                    for (let k = 0; k < res.patient.length; k++) {
                        let phoneNumer = res.patient[k].mobile_number
                        let easyview_uid = res.patient[k].easyview_uid
                        let sn = res.patient[k].transmitter_sn
                        if (phoneNumer && easyview_uid && obj[phoneNumer] && obj[phoneNumer].sensor_status && obj[phoneNumer].sensor_status.updateTime&&sn==Number(obj[phoneNumer].sensor_status.serial).toString(16).padStart(8, '0').toLocaleUpperCase()) {
                            res.patient[k].sg.push(...obj[phoneNumer].sg)
                            for(let m=0;m<obj[phoneNumer].bg.length;m++){
                                let item =[
                                    obj[phoneNumer].bg[m][2],
                                    obj[phoneNumer].bg[m][0],
                                    '',
                                    obj[phoneNumer].bg[m][1],
                                    ''
                                ]
                                res.patient[k].bg.push(item)
                            }
                            if (!res.patient[k].status.updateTime) {
                                res.patient[k].status = obj[phoneNumer].sensor_status
                                res.patient[k].ispro = true
                            } else if (obj[phoneNumer].sensor_status.updateTime >= res.patient[k].status.updateTime) {
                                res.patient[k].status = obj[phoneNumer].sensor_status
                                res.patient[k].ispro = true
                            }
                        }
                    }
                    sessionStorage.setItem('patienList', JSON.stringify(res.patient))
                    router.push({
                        name: 'patientManage',
                    })
                }
            })

        }
    })
}
function logout() {
    let val = {
        username: name.value,
        password: sessionStorage.getItem('password'),
    }
    instance.proxy.$http.post('/api/v2.1/center/logout', val).then((res) => {
        let error = res.error
        if (error == 0) {
            ElMessage.success('登出成功')
            sessionStorage.clear();
            ElNotification.closeAll()
            router.push({
                name: 'Login',
            })
        }
    })

}
function toUpdate() {
    router.push({
        name: 'updatePassword',
    })
}
function toDelete() {
    ElMessageBox.confirm('确定删除该患者？患者所有信息将被清除。', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
    }).then(() => {
        delPatient()
    }).catch(() => {

    })

}
function delPatient() {
    let val = {
        patient_id: sessionStorage.getItem('patient_id'),
        serial_number: sessionStorage.getItem('serial_number'),
    }
    instance.proxy.$http.post('/api/v2.1/center/patient/delete', val).then((res) => {
        let error = res.error
        if (error == 0) {
            ElMessage.success('删除成功')
            getPatienListDel()

        }
    })
}
function endPatient() {
    let val = {
        id: sessionStorage.getItem('patient_id'),
        state: 'out',
    }
    instance.proxy.$http.post('/api/v2.1/center/inpatient/end', val).then((res) => {
        let error = res.error
        if (error == 0) {
            ElMessage.success('出院成功')
            getPatienListDel()

        }
    })
}
function toEnd() {
    ElMessageBox.confirm('确定患者出院？患者发射器信息将被移除。', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
    }).then(() => {
        endPatient()
    }).catch(() => {

    })

}
function toprint() {
    router.push({
        name: 'printpropare',
    })
}
function toStop() {
    let tip = '确定暂停监控？暂停监控后，您将无法接收到血糖数据。'
    ElMessageBox.close()
    ElMessageBox.confirm(tip, '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
    }).then(() => {
        toSetStop()
    }).catch(() => {

    })

}
function toRec() {
    let id = sessionStorage.getItem('patient_id')
    let json = sessionStorage.getItem('patienList') || ''
    let patienList = JSON.parse(json)
    let patientData
    for (let i = 0; i < patienList.length; i++) {
        if (patienList[i].id == id) {
            patientData = patienList[i]
        }
    }
    let setting = patientData.setting
    setting.connect_sensor = 'Y'
    let val = {
        'id': patientData.id,
        'name': patientData.patient_info.name,
        'age': patientData.patient_info.age,
        'gender': patientData.patient_info.gender,
        'department_id': patientData.department_id,
        'ward': patientData.patient_info.ward,// 病区
        'bed': patientData.patient_info.bed,
        'diagonosis': patientData.patient_info.diagonosis,
        'admit': patientData.patient_info.admit,
        'transmitter_sn': patientData.transmitter_sn,
        'setting': JSON.stringify(setting)
    }
    instance.proxy.$http.post('/api/v2.1/center/patient/edit', val).then(res => {
        if (res.error == 0) {
            getPatienList()

        }
    })
}
function toSetStop() {
    let id = sessionStorage.getItem('patient_id')
    let json = sessionStorage.getItem('patienList') || ''
    let patienList = JSON.parse(json)
    let patientData
    for (let i = 0; i < patienList.length; i++) {
        if (patienList[i].id == id) {
            patientData = patienList[i]
        }
    }
    let setting = patientData.setting
    setting.connect_sensor = 'N'
    let val = {
        'id': patientData.id,
        'name': patientData.patient_info.name,
        'age': patientData.patient_info.age,
        'gender': patientData.patient_info.gender,
        'department_id': patientData.department_id,
        'ward': patientData.patient_info.ward,// 病区
        'bed': patientData.patient_info.bed,
        'diagonosis': patientData.patient_info.diagonosis,
        'admit': patientData.patient_info.admit,
        'transmitter_sn': patientData.transmitter_sn,
        'setting': JSON.stringify(setting)
    }
    instance.proxy.$http.post('/api/v2.1/center/patient/edit', val).then(res => {
        if (res.error == 0) {
            getPatienList()

        }
    })
}
let time = setInterval(() => {
    let path = router.currentRoute.value.path
    if (path == '/RealTimeMonitor' || path == '/patientMessage' || path == '/PersonalSettings' || path == '/printpropare' || path == '/EventRecord' || path == '/AddEventRecord' || path == '/editEventRecord') {
        let name = sessionStorage.getItem('patientName')
        patientName.value = name || ''
    } else {

        let name = sessionStorage.getItem('titlename')
        patientName.value = name || ''
    }
}, 1000)


</script>

<style scoped>
.main {
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    font-size: 24px;
    font-family: 'SourceHanSansCN';
    font-weight: 600;
    align-items: center;
    color: #888888;
    background: rgb(0, 173, 176);
    border-bottom: 1px solid #ccc;
}

.title {
    font-family: 'SourceHanSansCN';
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;
    margin-left: 120px;
}

.logout {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.el-dropdown-link {
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    outline: 0 !important;
}

>>>.el-icon {
    height: 100%;
}

.icon {
    height: 40px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.icon-home_logo:before {
    font-size: 40px;
    color: #fff;
}
</style>